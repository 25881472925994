import { LayoutDefault } from '@/components/layouts'

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/',
    name: 'Top',
    meta: { title: 'top', icon: '' },
    component: LayoutDefault,
    redirect: '/detection',
    hidden: true,
    children: [
      {
        path: 'detection',
        name: 'DetectionIndex',
        meta: {
          title: 'detection_index',
          icon: 'list_alt',
        },
        component: () => import('@/views/detection/Index.vue'),
        children: [
          {
            path: ':hash',
            name: 'DetectionDetail',
            meta: {
              title: 'detection_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/detection/Detail.vue'),
          },
        ],
      },
    ],
  },

  {
    path: '/403',
    name: 'Forbidden',
    meta: {
      title: 'error_403',
      hiddenInMenu: true,
    },
    component: () => import('@/views/error/Deny.vue'),
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'error_404',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'error_500',
    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedRoute = []
