import Axios from 'axios'
import store from '@/store/'

const axios = Axios.create()
axios.interceptors.request.use((request) => {
  const token = store.getters.getLoginToken
  if (token !== null) {
    request.headers = {
      'Access-Token': 'Bearer ' + token,
    }
  }
  return request
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if ('response' in error && 'status' in error.response && error.response.status == 401) {
      this.$router.push({
        path: '/auth/login',
        query: { backuri: this.$route.fullPath },
      })
    } else if ('response' in error && 'status' in error.response && error.response.status == 403) {
      this.$router.push({ path: '/403' })
    } else if ('response' in error && 'status' in error.response && error.response.status == 404) {
      this.$router.push({ path: '/404' })
    } else {
      alert(
        'Could not process your request. Please try again later.\n処理が正しく行えませんでした。時間をおいてやり直してください。',
      )
    }
  },
)
export default axios
