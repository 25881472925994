<template>
  <div class="admin">
    <app-toolbar class="admin_toolbar" extended @theme-settings-click="handleThemeSettings" />
    <v-main class="grey lighten-3">
      <!-- Page Wrapper -->
      <div class="page_wrapper"><router-view /></div>
      <!-- App Footer -->
      <v-footer height="auto" class="pa-3 app--footer">
        <v-row justify="center" no-gutters>
          <span>&copy; TKF Inc. {{ new Date().getFullYear() }}</span>
        </v-row>
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <app-fab />
  </div>
</template>

<script>
import AppToolbar from '@/components/AppToolbar'
import AppFab from '@/components/AppFab'

export default {
  name: 'LayoutDefault',
  components: {
    AppToolbar,
    AppFab,
  },

  data() {
    return {
      mini: false,
    }
  },
  methods: {
    handleThemeSettings() {
      this.$emit('theme-settings-click')
    },
  },
}
</script>

<style lang="sass" scoped>
.page_wrapper
  min-height: calc(100vh - 112px - 48px)
  padding-top: 35px
  .container
    max-width: 1200px
</style>
