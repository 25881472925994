import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import app from './modules/app'
import auth from './modules/auth'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    auth,
  },
  plugins: [
    createPersistedState({
      key: 'inventorymng',
      paths: ['app.locale', 'app.themeMode', 'app.themeColor', 'app.themeImage'],
      storage: localStorage,
    }),
  ],
})

export default store
