<template>
  <v-app-bar color="primary" dark app class="no-print">
    <span class="text-h4">捜索ドローン動画解析(仮)</span>
    <v-spacer />
    <v-toolbar-items>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon @click="handleFullScreen()" v-on="on">
            <v-icon>fullscreen</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('fullscreen') }}</span>
      </v-tooltip>
      <!-- locale -->
      <LocaleSwitch />
    </v-toolbar-items>
    <v-toolbar v-if="extended" slot="extension" tag="div" dense :light="$store.getters.getThemeMode != 'dark'">
      <v-icon>home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-3" />
      <v-spacer />
      <v-btn icon small>
        <v-icon @click="handleGoBack"> fa-light fa-arrow-left-long </v-icon>
      </v-btn>
    </v-toolbar>
  </v-app-bar>
</template>
<script>
import LocaleSwitch from '@/components/locale/LocaleSwitch'
import Util from '@/util'

export default {
  name: 'AppToolbar',
  components: {
    LocaleSwitch,
  },
  props: {
    extended: Boolean,
  },
  data() {
    return {
      profileMenus: [
        {
          icon: 'account_circle',
          title: 'user_info',
          click: this.handleProfile,
        },
        {
          icon: 'fa-tint',
          title: 'theme_setting',
          click: this.handleThemeSettings,
        },
        {
          icon: 'fa-sign-out',
          name: 'Logout',
          title: 'auth_logout',
          click: this.handleLogut,
        },
      ],
    }
  },
  computed: {
    breadcrumbs() {
      const { matched } = this.$route
      return matched.map((route, index) => {
        const to = index === matched.length - 1 ? this.$route.path : route.path || route.redirect
        const text = this.$t('menu.' + route.meta.title)
        return {
          text: text,
          to: to,
          exact: true,
          disabled: false,
        }
      })
    },
  },
  created() {},
  methods: {
    handleDrawerToggle() {
      this.$emit('side-icon-click')
    },
    handleFullScreen() {
      Util.toggleFullScreen()
    },
    handleProfile() {
      this.$router.push('/user/detail/' + this.$store.getters.getLoginUserID)
    },
    handleThemeSettings() {
      this.$emit('theme-settings-click')
    },
    handleLogut() {
      this.$router.push('/auth/logout')
    },
    handleGoBack() {
      this.$router.go(-1)
    },
  },
}
</script>
